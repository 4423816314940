<template>
  <div style="display: flex; flex-direction: column; height: calc(100vh - 180px)">
    <div class="condition-item">
      <el-button type="primary" style="margin-left: 10px; width: 120px" 
      @click="AddFirst"
       v-if="addFirstButton" >添加一级栏目</el-button
      >
    </div>
    <div style="margin-bottom: 15px; flex: 1; overflow: hidden">
      <el-table
        :data="columnlistTableData"
        row-key="cid"
        stripe
        border
        default-expand-all
        height="100%"
        style="width: 100%"
        :tree-props="{ children: 'childs', hasChildren: 'haschilds' }"
      >
        <template #empty>
          <p>
            {{ tableTaskAndLoading == true ? "数据加载中" : "暂无数据" }}
          </p>
        </template>
        <!-- <el-table-column
          label="序号"
          width="150"
          align=""
        >

      </el-table-column> -->
        <el-table-column prop="title" label="栏目标题"></el-table-column>

        <el-table-column prop="amount" label="是否显示" width="240" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.isExternalLink == 1">外部</span>
            <span v-if="scope.row.isShow == 1">显示</span>
            <span v-else>不显示</span>
          </template>
        </el-table-column>
        <el-table-column prop="workTime" label="排序" width="100">
          <template slot-scope="scope">
            <el-input
              v-model:value="scope.row.showOrder"
              @change="changeshowOrder(scope.row.cid, scope.row.showOrder)"
              size="small"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="mergenPaymentFlag" label="操作" width="200" align="center">
          <template slot-scope="scope">
            <el-button
            v-if="addNextButton"
              type="text"
              @click="AddNext(scope.row.cid)"
              v-show="scope.row.pcid == 0"
              >添加下级栏目</el-button
            >
            <el-button type="text" 
            v-if="editButton"
            @click="AddEdit(scope.row)">修改</el-button>
            <el-button type="text" 
            v-if="deleteButton"
            @click="deleteOne(scope.row.cid)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- <div style="display: flex; justify-content: right">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div> -->
    <!-- 上添加网站栏目弹窗 -->

    <el-dialog :title="columnShowTtle" :visible.sync="columnShow" width="900px">
      <el-form
        ref="columnShowData"
        :model="columnShowData"
        label-width="120px"
        :rules="columnRules"
      >
        <el-form-item label="栏目标题" prop="title">
          <el-input
            v-model="columnShowData.title"
            type="text"
            placeholder="请输入栏目标题"
          ></el-input>
        </el-form-item>

        <el-form-item label="是否外部地址" prop="isExternalLink">
          <el-checkbox v-model="isExternalLink">(选中为外部地址)</el-checkbox>
        </el-form-item>
        <el-form-item label="跳转地址	" prop="listUrl" v-show="isExternalLink">
          <el-input
            v-model="columnShowData.linkUrl"
            type="text"
            placeholder="请输入列表页地址"
            style="width: 460px"
          ></el-input>
          <span style="margin-left: 40px">打开方式：</span>
          <el-select
            v-model="columnShowData.linkTarget"
            placeholder="打开方式"
            style="width: 120px"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="列表页地址" prop="listUrl" v-show="!isExternalLink">
          <el-input
            v-model="columnShowData.listUrl"
            type="text"
            placeholder="请输入列表页地址"
            style="width: 460px"
          ></el-input>
          <span style="margin-left: 40px">打开方式：</span>
          <el-select
            v-model="columnShowData.listTarget"
            placeholder="打开方式"
            style="width: 120px"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="内容页地址" prop="contentUrl" v-show="!isExternalLink">
          <el-input
            v-model="columnShowData.contentUrl"
            type="text"
            placeholder="请输入内容页地址"
            style="width: 460px"
          ></el-input>
          <span style="margin-left: 40px">打开方式：</span>
          <el-select
            v-model="columnShowData.contentTarget"
            placeholder="打开方式"
            style="width: 120px"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="是否显示" prop="isShow">
          <el-radio-group v-model="columnShowData.isShow">
            <el-radio :label="1">(选中为显示址)</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="排序"
          prop="showOrder
        "
        >
          <el-input
            v-model="columnShowData.showOrder"
            type="text"
            placeholder="请输入排序号"
          ></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="handleConfirm"
          style="margin-left: 20px"
          :loading="Confirmloading"
          >保 存
        </el-button>
        <el-button @click="handleCancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  queryTfCmsColumns,
  updateTfCmsColumn,
  deleteTfCmsColumn,
  addTfCmsColumn,
} from "@/api/informationMg";
import { queryPageButton } from "@/api/permission";

export default {
  name: "Columnlist",

  data() {
    // const validateContactName = (rule, value, callback) => {
    //   if (!value) {
    //     callback(new Error("联系人不能为空"));
    //   } else {
    //     callback();
    //   }
    // };
    return {
      /**列表参数 */
      columnlistTableData: [],
      tableTaskAndLoading: false,
      // total: 0,
      // totalAmount: 0,
      // currentPage: 1,
      // pageSize: 20,

      /**弹窗参数 */

      AddNextpCId: null, //下一级别pCId
      columnShowTtle: "添加网站栏目",
      columnShow: false,
      Confirmloading: false,
      columnShowData: {},
      isExternalLink: false,
      options: [
        {
          value: "_self",
          label: "_self",
        },
        {
          value: "_blank",
          label: "_blank",
        },
        {
          value: "_top",
          label: "_top",
        },
      ],

      columnRules: {
        // linkUrl: [
        //   {
        //     required: true,
        //     trigger: "blur",
        //     validator: validateContactName,
        //   },
        // ],
      },
      //权限按钮
      addFirstButton:false,
      addNextButton:false,
      editButton:false,
      deleteButton:false
    };
  },
  created() {
    this.queryData();
    this.queryButton();

  },
  methods: {
    queryButton() {
      const data = 142;
      queryPageButton(data).then((resp) => {
        for (const button of resp.data) {
          if (button.code === "addFirstButton") {
            this.addFirstButton = true;
          }   if (button.code === "addNextButton") {
            this.addNextButton = true;
          }  if (button.code === "editButton") {
            this.editButton = true;
          }  if (button.code === "deleteButton") {
            this.deleteButton = true;
          } 
        
          
        }
      });
    },
    // 列表数据
    queryData() {
      this.tableTaskAndLoading = true;
      queryTfCmsColumns().then((res) => {
        if (res.code == 0) {
          this.columnlistTableData = res.data;
          this.tableTaskAndLoading = false;
        }
      });
    },

    //排序修改
    changeshowOrder(cid, showOrder) {
      if (showOrder) {
        let data = {
          cid: cid,
          showOrder: showOrder,
        };
        updateTfCmsColumn(data)
          .then((res) => {
            if (res.code == 0) {
              this.$notify({
                title: "温馨提示",
                message: res.message,
                type: "success",
              });
            } else {
              this.$notify({
                title: "警告",
                message: res.data.msg,
                type: "warning",
              });
            }
            this.queryData();
          })
          .catch((error) => {
            this.$notify({
              title: "提示",
              message: error,
              type: "warning",
            });
            this.queryData();
          });
      } else {
        this.$notify({
          title: "警告",
          message: "排序号不能为空",
          type: "warning",
        });
        this.queryData();
      }
    },

    /*
      添加一级栏目
       */
    AddFirst() {
      this.columnShowTtle = "添加网站栏目";
      this.columnShow = true;
      this.columnShowData = {};
    },
    /*
      添加下级栏目
       */
    AddNext(pCId) {
      this.columnShowTtle = "添加网站栏目";
      this.AddNextpCId = pCId;
      this.columnShow = true;
      this.columnShowData = {};
    },
    /*
      修改
       */
    AddEdit(data) {
      this.columnShowTtle = "修改网站参数";
      if (data.isExternalLink == 1) {
        this.isExternalLink = true;
      } else if (data.isExternalLink == 0) {
        this.isExternalLink = false;
      }
      this.columnShow = true;
      this.columnShowData = data;
    },
    handleConfirm() {
      this.Confirmloading = true;
      let postUrl = null;
      if (this.isExternalLink == true) {
        this.columnShowData.isExternalLink = 1;
      } else if (this.isExternalLink == false) {
        this.columnShowData.isExternalLink = 0;
      }
      if (this.columnShowTtle == "添加网站栏目") {
        postUrl = addTfCmsColumn;
        if (this.columnShowTtle == "修改网站参数") {
          this.columnShowData.pcid = this.AddNextpCId;
        }
      } else {
        postUrl = updateTfCmsColumn;
      }
      postUrl(this.columnShowData)
        .then((res) => {
          if (res.code == 0) {
            this.$notify({
              title: "温馨提示",
              message: res.message,
              type: "success",
            });
          } else {
            this.$notify({
              title: "警告",
              message: res.data.msg,
              type: "warning",
            });
          }
          this.Confirmloading = false;
          this.columnShow = false;
          this.queryData();
        })
        .catch((error) => {
          this.$notify({
            title: "提示",
            message: error,
            type: "warning",
          });
          this.Confirmloading = false;
          this.columnShow = false;
          this.queryData();
        });
    },
    handleCancel() {
      this.columnShow = false;
    },
    /*
      删除
       */
    deleteOne(cid) {
      this.$confirm("此操作将删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteTfCmsColumn(cid).then((res) => {
          if (res.code == 0) {
            this.$notify({
              title: "温馨提示",
              message: res.message,
              type: "success",
            });
          } else {
            this.$notify({
              title: "警告",
              message: res.data.msg,
              type: "warning",
            });
          }

          this.queryData();
        });
        //
      });
    },

    // handleSizeChange(val) {
    //   this.pageSize = val;
    //   this.queryData();
    // },
    // handleCurrentChange(val) {
    //   this.currentPage = val;
    //   this.queryData();
    // },
  },
};
</script>

<style scope>
.condition-item {
  margin-right: 10px;
}
</style>
