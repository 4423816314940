import request from "@/utils/request";

//栏目管理的列表
export function queryTfCmsColumns() {
  return request({
    url:
      "/sys/tfCmsColumn/queryTfCmsColumns",
    method: "post",
   
  });
}


//栏目管理的新增
export function addTfCmsColumn(data={}) {
  return request({
    url:
      "sys/tfCmsColumn/addTfCmsColumn" ,
    method: "post",
    data,
   
  });
}


//栏目管理的修改
export function updateTfCmsColumn(data={}) {
  return request({
    url:
      "sys/tfCmsColumn/updateTfCmsColumn" ,
    method: "post",
    data,
   
  });
}


//栏目管理的删除
export function deleteTfCmsColumn(cId) {
  return request({
    url:
      "sys/tfCmsColumn/deleteTfCmsColumn/"+cId ,
    method: "get",
   
  });
}



//信息列表
export function queryTfCmsArticles(currentPage, pageSize = 20, data) {
  return request({
    url: "sys/tfCmsArticle/queryTfCmsArticles?currentPage=" + currentPage + "&pageSize=" + pageSize,
    headers: {
      "Content-Type": "application/json",
    },
    method: "post",
    data,
  });
}

//信息列表的修改
export function updateTfCmsArticle(data={}) {
  
  return request({
    url:
      "sys/tfCmsArticle/updateTfCmsArticle" ,
      headers: {
        "Content-Type": "application/json",
      },
    method: "post",
    data,
   
  });
}

//栏目所属列表
export function queryTfCmsColumnList() {
  
  return request({
    url:
      "sys/tfCmsColumn/queryTfCmsColumnList" ,
    method: "get",
   
  });
}

//信息列表的添加
export function addTfCmsArticle(data={}) {
  return request({
    url:
      "sys/tfCmsArticle/addTfCmsArticle" ,
      headers: {
        "Content-Type": "application/json",
      },
    method: "post",
    data,
   
  });
}


//信息列表的删除
export function deleteTfCmsArticle(aid) {
  
  return request({
    url:
      "sys/tfCmsArticle/deleteTfCmsArticle/"+aid ,
    method: "get",
   
  });
}

//文件上传
export function uploadFileNew(data) {
  return request({
    url: "/file/uploadFileNew",
    method: "post",
    data,
  });
}

